import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import Auth from "../../../../utils/auth";
import { useNavigate } from "react-router-dom";
import {
  getUserProfile,
  getAgentProfile,
  addAgent,
  postNotice,
  sendConfirmation,
} from "../../../../utils/api";
import { updateUser } from "../../../../utils/api";
import editImage from "../../../../assets/edit.png";
import imagePath from "../../../../utils/image";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  const [agents, setAgents] = useState("");
  const [isBusca, setIsBusca] = useState(false);
  const [isFindahome, setIsFindahome] = useState(false);

  // Run Query Once
  useEffect(() => {
    // User token
    let currentUser;
    if (Auth.loggedIn() !== false) {
      currentUser = Auth.getUser();

      getUserProfile(currentUser.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // Set the from states with db data
          setUser({
            id: data.id,
            name: data.name,
            email: data.email,
          });

          setAgents(data.agents);

          for (let i = 0; i < data.agents.length; i++) {
            const agent = data.agents[i];
            if (agent.primary_language === "English") {
              setIsFindahome(true);
            }
            if (agent.primary_language === "Spanish") {
              setIsBusca(true);
            }
            if (agent.primary_language === "Both") {
              setIsFindahome(true);
              setIsBusca(true);
            }
          }

          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      navigate(`/user-login`);
    }
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");

  const displayPhoto = (image) => {
    // console.log(image);
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  const getArea = (agent) => {
    const city = agent.areas_served.split("; ")[0];
    return city;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (email === user.email || email.trim() === "") {
      return;
    } else {
      const userData = {
        email: email,
      };

      updateUser(userData, user.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setShowError(true);
        });
    }
  };

  const duplicateAgent = (website) => {
    const lang = website;
    const agentId = agents[0].id;

    // Get Existing Agent Data
    getAgentProfile(agentId)
      .then((res) => res.json())
      .then((data) => {
        // Gather Agent Data for Query
        const formData = {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          languages_spoken: data.languages_spoken,
          primary_language: website,
          areas_served: data.areas_served,
          state_license_numbers: data.state_license_numbers,
          profile_description: data.profile_description,
          brokerage: data.brokerage,
          brokerage_number: data.brokerage_number,
          image: data.image,
          user_id: data.user_id,
          isDeleted: true,
        };

        // Create Agent
        addAgent(formData)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            const newAgent = data.newAgent;

            const noticeData = {
              content: `${newAgent.first_name} ${newAgent.last_name} created an Agent Account`,
              date: moment(),
              actions: "Approve Agent",
              actions_content: newAgent.id,
              website: lang === "English" ? "Findahome" : "Busca",
              user_id: newAgent.user_id,
              agent_id: newAgent.id,
            };

            postNotice(noticeData)
              .then((res) => res.json())
              .then((data) => {
                console.log(data);

                // Send an email to Santiago
                sendConfirmation()
                  .then((res) => res.json())
                  .then((data) => {
                    console.log(data);
                  })
                  .catch((err) => console.log(err));

                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  if (isLoading) {
    return <>loading...</>;
  }

  return (
    <div className="agent-portal">
      <Header user={user} />

      {/* Main */}
      <main>
        <div className="ap-dashboard">
          <main>
            {/* Account Information */}
            <div className="ap-account-details">
              <h1>Account Details</h1>

              {isEditing === true ? (
                <form onSubmit={handleFormSubmit} className="ap-details-input">
                  <div>
                    <label htmlFor="email" className="loans-form-label">
                      {" "}
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="loans-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="ap-details-btns">
                    <button onClick={() => setIsEditing(false)}>Cancel</button>
                    <button className="primary" type="submit">
                      Update
                    </button>
                  </div>

                  {showError ? (
                    <div className="message-container">
                      <p className="error-message">
                        Something has gone wrong with your request, please try
                        again later.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              ) : (
                <p>
                  Email: {user.email}{" "}
                  <span>
                    <img
                      src={editImage}
                      alt="Edit information."
                      onClick={() => setIsEditing(true)}
                    />
                  </span>
                </p>
              )}
            </div>

            {/* Card Preview */}
            {agents.map((agent) => (
              <div
                key={agent.id}
                className={agent.primary_language === "English" ? "" : "busca"}
              >
                <div style={{ textAlign: "left" }}>
                  <p style={{ marginBlockEnd: "1px" }}>
                    <b>
                      {agent.primary_language === "English"
                        ? "Find a Home Card"
                        : "Busca Tu Casa Card"}
                    </b>
                  </p>
                  <p style={{ marginBlockStart: "1px" }}>
                    <b>Subscription Tier:</b> {agent.plan_type}
                  </p>
                  <div style={{ width: "100%", marginBottom: "7px" }}>
                    <button
                      className="text-shadow agent-card-btn"
                      onClick={() => {
                        const storedAgent = {
                          name: `${agent.first_name} ${agent.last_name}`,
                          id: agent.id,
                          website:
                            agent.primary_language === "English"
                              ? "Findahome"
                              : "Busca",
                        };
                        localStorage.setItem(
                          "agent",
                          JSON.stringify(storedAgent)
                        );
                        localStorage.setItem("plan_type", "Standard");

                        window.location.href =
                          "https://buy.stripe.com/4gw8Av5bCcPIbtefZ2";
                      }}
                    >
                      Upgrade to Standard
                    </button>
                  </div>
                  <button
                    className="text-shadow agent-card-btn"
                    onClick={() => {
                      const storedAgent = {
                        name: `${agent.first_name} ${agent.last_name}`,
                        id: agent.id,
                        website:
                          agent.primary_language === "English"
                            ? "Findahome"
                            : "Busca",
                      };
                      localStorage.setItem(
                        "agent",
                        JSON.stringify(storedAgent)
                      );
                      localStorage.setItem("plan_type", "Pro");

                      window.location.href =
                        "https://buy.stripe.com/aEU7wr6fGeXQ40MeV1";
                    }}
                  >
                    Upgrade to Pro
                  </button>
                </div>

                {agent.isDeleted === true ? (
                  <p className="ap-card-alert">
                    This is a preview of how your profile card will be seen by
                    others. Once your profile is approved, you’ll be able to
                    edit your information by clicking on “Edit Profile”.
                  </p>
                ) : (
                  <></>
                )}

                <div className="ap-card-conatiner">
                  <div className="agent-search-result-card ap-card">
                    <div className={!agent.image ? "" : "agent-card-top"}></div>
                    <div className="agent-card-content">
                      <img
                        src={displayPhoto(agent.image)}
                        alt="Agent Headshot"
                      />
                      <h3>
                        {agent.first_name} {agent.last_name}
                      </h3>
                      <p>{getArea(agent)}</p>
                      <p>{agent.email}</p>
                      <p>{agent.phone_number}</p>
                      <div className="agent-card-btn-container">
                        <div>
                          <button
                            className={
                              agent.isDeleted === false
                                ? "text-shadow agent-card-btn"
                                : "text-shadow agent-card-btn disabled"
                            }
                            onClick={() =>
                              navigate(`/agent-portal-edit/${agent.id}`)
                            }
                          >
                            Edit Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </main>

          <div className="justify-center">
            {isBusca ? (
              <></>
            ) : (
              <button
                onClick={() => duplicateAgent("Spanish")}
                className="btn-purple"
              >
                Create a Busca tu Casa Agent Account
              </button>
            )}
            {isFindahome ? (
              <></>
            ) : (
              <button
                onClick={() => duplicateAgent("English")}
                className="btn-primary"
              >
                Create a Find a Home Agent Account
              </button>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
